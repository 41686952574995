import Table from '../../components/Table';
import {ExportOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import {Button, Spin} from 'antd';
import axios from 'axios';
import env from '../../env-config';


const Title = "Bienes";

const columns = ["Identificación", "Nombre", "Tipo"];







const Bienes = () => {

    const [selectedRows, setSelectedRows] = useState([]);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // Estado de carga


    const customToolbarSelect = () => {

      const handleDetalles = () => {
        const selectedUser = data[selectedRows[0].dataIndex];
        const id = selectedUser[0];
        const tipo = selectedUser[2];

        if(tipo === 'Inmueble'){
          sessionStorage.setItem('InmuebleID',id);
          window.location.href = '/inmueble';
          sessionStorage.setItem('lastPage', 'bienes');
        }
        else{
          sessionStorage.setItem('MuebleID',id);
          window.location.href = '/mueble';
          sessionStorage.setItem('lastPage', 'bienes');
        }

      };

      return (
        <>
          <div>

            <Button type='text' className='toggle' icon={<ExportOutlined />} onClick={handleDetalles} title="Ver Detalles" />
                        
          </div>
        </>
      );
    };
   // Cargar Datos
   useEffect(() => {
    sessionStorage.setItem('currentPage', 'Bienes');
    const CustomerID = sessionStorage.getItem('CustomerID');
    if (!CustomerID) {
      // Redirigir al login si no hay compañía
      window.location.href = '/login';
      return;
    }
    
    let URL = env.API_URL;


    let ID = ''; 
    const TipoBien = sessionStorage.getItem('TipoBien');

    if (TipoBien === 'Sociedad') {
      URL = URL + 'GetBienesSociety';
      ID = sessionStorage.getItem('SocietyID');
    }
    else{
      URL = URL + 'GetBienesCustomer';
      ID = CustomerID;
    }


    const fetchData = async () => {

      const data = {
        ID: ID  
      };

      try {
        const response = await axios.post(URL,data);
        const formattedData = response.data.map(user => [
          user.Matricula,
          user.Nombre,
          user.Tipo
        ]);
        setData(formattedData);
      } catch (error) {
        console.error("Error fetching data", error.message);
      } finally {
        setIsLoading(false); // Cambia el estado de carga
      }
    };

    fetchData();
  }, []);


    return (
        <div>
          {isLoading ? (
        <Spin tip="Cargando datos..." style={{ display: 'flex', justifyContent: 'center', marginTop: '20%' }} />
      ) : (
            <Table title={Title} data={data} columns={columns} customToolbarSelectFunction={customToolbarSelect} setSelectedRows={setSelectedRows} />
      )}
        </div>
    )
}

export default Bienes;