import { Menu } from "antd";
import { HomeOutlined,
        LogoutOutlined,
        CloudSyncOutlined,
        ZoomInOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom"; 

const MenuList = ({ setOptionsVisibility, OpenChangeModal, setPage}) => {

    
    return (
        <Menu className="menu-bar">
            <Menu.Item key="Clientes" icon={<HomeOutlined/>}>
                <Link to="/Clientes" onClick={() => { setOptionsVisibility(true); setPage('Clientes')  }}>Cliente</Link> 
            </Menu.Item>

            <Menu.Item key="Busqueda" icon={<ZoomInOutlined />}>
                <Link to="/Busqueda" onClick={()=> {setOptionsVisibility(false);}}>Busqueda</Link>
            </Menu.Item>

            <Menu.Item key="ChangePassword" icon={<CloudSyncOutlined /> } onClick={OpenChangeModal}>
                Contraseña
            </Menu.Item>

            <Menu.Item key="LogOut" icon={<LogoutOutlined />}>
                <Link to="/">Cerrar Sesión</Link> 
             </Menu.Item>   
        </Menu>
    );
}

export default MenuList;