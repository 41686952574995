import React, { useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import env from '../../env-config';
import Swal from 'sweetalert2';
import axios from 'axios';
import InputComponent from './InputsDialog';
import 'bootstrap/dist/css/bootstrap.min.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ open, handleClose }) {
  const getValueRefs = useRef({});

  const handleAdd = async () => {
    const idLogin = sessionStorage.getItem('loginUser');

    const data = {
      Name: getValueRefs.current["Nombre"].value,
      Mail: getValueRefs.current["Correo"].value,
      Address: getValueRefs.current["Direccion"].value,
      PM: document.getElementById("PM").value === "true", 
      FB: document.getElementById("FB").value === "true", 
      Lawyer: getValueRefs.current["Abogado"].value,
      ID: getValueRefs.current["Cedula"].value,
      ID_Type: document.getElementById("DocumentType").value,  
      DocumentType: document.getElementById("DocumentType").value, // Nuevo campo de tipo de documento
      IDUSUARIO: idLogin
    };

    try {
      const response = await axios.post(env.API_URL+'AddCustomer', data);
      if (response.status === 200) {
        Swal.fire('Success', 'Se ha creado el cliente', 'success').then(() => {
          window.location.reload();
        }); 
      } else {
        Swal.fire('Error', 'Hubo un problema al crear el cliente', 'error');
      }
      handleClose();
    } catch (error) {
      console.log('error:', error.message); 
      Swal.fire('Error', 'Hubo un problema al establecer la conexión', 'error');
      handleClose();
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{"Crear Cliente"}</DialogTitle>
        <DialogContent>
          <div className='Row'>
            <InputComponent
              getValue={getValueRefs}
              id="Nombre"
              label="Nombre:"
              type="text"
              placeholder=""
            />
            <InputComponent
              getValue={getValueRefs}
              id="Correo"
              label="Correo:"
              type="text"
              placeholder=""
            />
            <InputComponent
              getValue={getValueRefs}
              id="Direccion"
              label="Dirección:"
              type="text"
              placeholder=""
            />
          </div>

          <div className='Row'>
            <InputComponent
              getValue={getValueRefs}
              id="Abogado"
              label="Abogado:"
              type="text"
              placeholder=""
            />
            <div className="col-md-4 col-sm-4">
              <label className="form-label">PM</label>
              <select className="form-control" id="PM">
                <option value="true">Sí</option>
                <option value="false">No</option>
              </select>
            </div>
            <div className="col-md-4 col-sm-4">
              <label className="form-label">FB:</label>
              <select className="form-control" id="FB">
                <option value="true">Sí</option>
                <option value="false">No</option>
              </select>
            </div>
          </div>

          <div className='Row'>
            <div className="col-md-4 col-sm-4">
              <label className="form-label">Tipo de Documento</label>
              <select className="form-control" id="DocumentType">
                <option value="Cédula">Cédula</option>
                <option value="DIMEX">DIMEX</option>
                <option value="Pasaporte">Pasaporte</option>
                <option value="Licencia de conducir">Licencia de conducir</option>
              </select>
            </div>
            <InputComponent
              getValue={getValueRefs}
              id="Cedula"
              label="Identidad:"
              type="text"
              placeholder=""
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleAdd}>Agregar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
