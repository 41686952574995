import React, { useState } from 'react';
import Table from '../../components/Table';
import { SearchOutlined, ExportOutlined } from '@ant-design/icons';
import { Input, Button} from 'antd';
import axios from 'axios';
import Swal from 'sweetalert2';
import env from '../../env-config';

const Title = "Busqueda";
const columns = ["Cedula", "Nombre", "Tipo"];

const Searching = () => {
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  // Lógica para buscar y obtener datos
  const handleSearch = async () => {
    if (!searchQuery) {
      Swal.fire('Error', 'Por favor ingrese un ID para buscar', 'error');
      return;
    }

    let URL = env.API_URL + 'Search';

    const data = {
      ID: searchQuery
    };
    try {
      const response = await axios.post(URL, data);

      if (response.status === 200) {
        // Suponiendo que la respuesta es un array de objetos con Cedula, Nombre, y Tipo
        const formattedData = response.data.map(user => [
          user.Cedula,
          user.Nombre,
          user.Tipo
        ]);
        setData(formattedData);
      } else {
        Swal.fire('Error', 'No se encontraron resultados', 'error');
      }
    } catch (error) {
      Swal.fire('Error', 'Hubo un problema al realizar la búsqueda', 'error');
    }
  };

  const customToolbarSelect = () => {
    const handleDetalles = () => {
      const selectedUser = data[selectedRows[0].dataIndex];
      const id = selectedUser[0]; 
      const tipo = selectedUser[2];

      switch (tipo) {
        case 'Cliente':
          sessionStorage.setItem('CustomerIDS', id);
          window.location.href = '/cliente';
          break;
        case 'Sociedad':
          sessionStorage.setItem('SocietyIDS', id);
          window.location.href = '/sociedad';
          break;
        
        case 'Mueble':
          sessionStorage.setItem('MuebleID', id);
          window.location.href = '/mueble';
          break;

        case 'Inmueble':
          sessionStorage.setItem('InmuebleID', id);
          window.location.href = '/inmueble';
          break;

        default:
          Swal.fire('Error', 'Tipo de usuario no reconocido', 'error');
          break;
      }


    };

    return (
      <>
        <div>
          <Button type='text' className='toggle' icon={<ExportOutlined />} onClick={handleDetalles} title="Ver detalles" />
        </div>
      </>
    );
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 16 }}>
        <Input 
          placeholder="Buscar por ID"
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          style={{ width: 200, marginRight: 8 }}
        />
        <Button 
          type="primary" 
          icon={<SearchOutlined />} 
          onClick={handleSearch}
        >
          Buscar
        </Button>
      </div>

      <Table 
        title={Title} 
        data={data} 
        columns={columns} 
        customToolbarSelectFunction={customToolbarSelect} 
        setSelectedRows={setSelectedRows}
      />
    </>
  );
};

export default Searching;
