import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faEnvelope, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import logo2 from '../../img/logo2.png';
import '../../css/Login.css';
import env from '../../env-config.js'; // Importa las variables de entorno
import Swal from 'sweetalert2'; // Importa SweetAlert


function Login() {
  const [isSignUpMode, setIsSignUpMode] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // New state for signup fields
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const handleSignUpClick = () => {
    setIsSignUpMode(true);
  };

  const handleSignInClick = () => {
    setIsSignUpMode(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(env.API_URL + 'Login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ Login: username, Pass: password }),
      });

      const data = await response.json();

      if (data && data.length > 0) {
        let Id = data[0].ID;
        let Rol = data[0].Rol;
        let User = data[0].Usuario;
        sessionStorage.setItem('loginUser', Id);
        sessionStorage.setItem('loginNick', User);
        sessionStorage.setItem('loginRol', Rol);


        if (data[0].Mensaje === 'OK') {
          window.location.href = '/Clientes';
        } else {
          setErrorMessage('Error al iniciar sesión, revise el usuario y la contraseña');
        }

      } else {
        setErrorMessage('Error al iniciar sesión, revise el usuario y la contraseña');
      }
    } catch (error) {
      console.log('Error:', error.message);
      setErrorMessage('Error al conectar con el servidor');
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    try {
      console.log('Registrando usuario:', name, username, password, email);
      const response = await fetch(env.API_URL + 'AddUser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ Name: name, User: username, Pass: password, Mail: email, Rol: 'Normal' }),
      });

      const data = await response.json();

      
      console.log(data);
      if (data && data.length > 0) {
        console.log('Usuario registrado:', data[0].Mensaje);
        if (data[0].Mensaje === 'OK'){
          handleSignInClick(); // Switch to login mode
          setIsSignUpMode(false); // Switch back to login mode
        }
        else{
          setErrorMessage('Error al registrarse, revise los datos e intente nuevamente');
        }

      } else {
        setErrorMessage('Error al registrarse, revise los datos e intente nuevamente');
      }
    } catch (error) {
      console.log('Error:', error.message);
      setErrorMessage('Error al conectar con el servidor');
    }
  };


 

  const handleRecoverPassword = async () => {
    if (!username) {
      Swal.fire({
        title: "Error",
        text: "El campo de usuario no puede estar vacío.",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    } else {
      Swal.fire({
        title: "Confirmación",
        text: "¿Desea recuperar la contraseña?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, recuperar",
        cancelButtonText: "No, cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await fetch(env.API_URL + 'ForgetPassword', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ ID: username }),
            });
  
            const data = await response.json();
  
            console.log(data);
  
            if (data.Mensaje === 'ERROR') {
              Swal.fire({
                title: "Error",
                text: "Usuario no encontrado.",
                icon: "error",
                confirmButtonText: "Aceptar",
              });
            } else {
              Swal.fire({
                title: "Correo enviado",
                text: "Se ha enviado un correo con la contraseña a la dirección de correo asociada al usuario.",
                icon: "success",
                confirmButtonText: "Aceptar",
              });
            }
  
          } catch (error) {
            console.log('Error:', error.message);
            Swal.fire({
              title: "Error",
              text: "Error al conectar con el servidor.",
              icon: "error",
              confirmButtonText: "Aceptar",
            });
          }
        } else {
          Swal.fire("Cancelado", "La recuperación de la contraseña ha sido cancelada", "info");
        }
      });
    }
  };

  return (
    <div className={`loginContainer ${isSignUpMode ? 'sign-up-mode' : ''}`}>
      <div className="forms-container">
        <div className="signin-signup">
          {isSignUpMode ? (
            <form className="sign-up-form loginForm" onSubmit={handleSignUp}>
              <h2 className="title">Registrarse</h2>

              <div className="input-field">

                <FontAwesomeIcon icon={faEnvelope} className='my-auto mx-auto InputIcon' />
                <input
                  className='LoginInput'
                  type="text"
                  placeholder="Nombre"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="input-field">

                <FontAwesomeIcon icon={faEnvelope} className='my-auto mx-auto InputIcon' />

                <input
                  className='LoginInput'
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="input-field">

                <FontAwesomeIcon icon={faEnvelope} className='my-auto mx-auto InputIcon' />
                <input
                  className='LoginInput'
                  type="text"
                  placeholder="Usuario"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="input-field">

                <FontAwesomeIcon icon={faLock} className='my-auto mx-auto InputIcon'/>

                <input
                  className='LoginInput'
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Contraseña"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button type="button" className="password-toggle-btn" onClick={togglePasswordVisibility}>
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </button>
              </div>

              {errorMessage && <p className="error-message">{errorMessage}</p>}

              <button className='btn' type="submit">
                Registrarse
              </button>
            </form>
          ) : (
            <form className="sign-in-form loginForm" onSubmit={handleLogin}>
              <h2 className="title">Iniciar Sesión</h2>

              <div className="input-field">

                <FontAwesomeIcon icon={faEnvelope} className='my-auto mx-auto InputIcon' />

                <input
                  className='LoginInput'
                  type="text"
                  placeholder="Usuario"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="input-field">

                <FontAwesomeIcon icon={faLock} className='my-auto mx-auto InputIcon' />

                <input
                  className='LoginInput'
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Contraseña"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button type="button" className="password-toggle-btn" onClick={togglePasswordVisibility}>
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </button>
              </div>

              <button type="button" onClick={handleRecoverPassword} className='recover-link'>
                ¿Olvidó su contraseña?
              </button>


              {errorMessage && <p className="error-message">{errorMessage}</p>}

              <button className='btn' type="submit">
                Iniciar Sesión
              </button>
            </form>
          )}
        </div>
      </div>
      <div className="panels-container">
        <div className="panel left-panel">
          <div className="content">

            <h3 className='loginh3'>¿Desea Registrarse?</h3>

            <p className='loginp'>
              ㅤㅤHaga click en el siguiente botón para dirigirse a la pestaña de registro del sistema.ㅤㅤ
            </p>
            <button className="btn transparent" onClick={handleSignUpClick}>
              Registrarse
            </button>
          </div>
          <img src={logo2} className="image" alt="" />
        </div>
        <div className="panel right-panel">
          <div className="content">
            <h3 className='loginh3'>¿Desea Iniciar Sesión?</h3>
            <p className='loginp'>
              Haga click en el siguiente botón para dirigirse a la pestaña de inicio de sesión en el sistema.
            </p>
            <button onClick={handleSignInClick} className="btn transparent" id="sign-in-btn">
              Iniciar Sesión
            </button>
          </div>
          <img src={logo2} className="image" alt="" />
        </div>
      </div>
    </div>
  );
}

export default Login;
