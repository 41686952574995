import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import env from '../../../env-config';
import InputEditComponent from './InputsEditDialog';
import 'bootstrap/dist/css/bootstrap.min.css';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { esES } from '@mui/x-date-pickers/locales';

// Transición para el diálogo modal
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditInmuebleModal({ open, handleClose }) {
  const [ID, setID] = useState('');
  const [data, setData] = useState({});
  const [buyTime, setBuyTime] = useState(dayjs());
  const [reset, setReset] = useState(false);
  const [UDMTime, setUDMTime] = useState(dayjs());
  const getValueRefs = useRef({});

  const [garbage, setGarbage] = useState(data.Basura ? "true" : "false");
  const [publicServices, setPublicServices] = useState(data.ServiciosPublicos ? "true" : "false");
  const [solidarityTax, setSolidarityTax] = useState(data.ImpuestoSolidario ? "true" : "false");
  
  useEffect(() => {
    if (data) {
      setGarbage(data.Basura ? "true" : "false");
      setPublicServices(data.ServiciosPublicos ? "true" : "false");
      setSolidarityTax(data.ImpuestoSolidario ? "true" : "false");
    }
  }, [data]);

  useEffect(() => {
    if (open) {
      const ID = sessionStorage.getItem('InmuebleID');
      setID(ID);

      const fetchData = async () => {
        try {
          const response = await axios.post(env.API_URL + 'GetInmueble', { ID });
          const formattedData = response.data[0];

          const adjustDate = (dateStr) => {
            if (!dateStr) return null;
            return dayjs(dateStr); 
          };

          const originalDateStr = formattedData.FechaCompra;
          const buyDate = adjustDate(originalDateStr);
          const originalDay = parseInt(originalDateStr.split('T')[0].split('-')[2], 10); 
          const buyDay = buyDate.date();
          const dayDifference = buyDay - originalDay;
          if (dayDifference !== 0) {
            const adjustedBuyDate = buyDate.add(-dayDifference, 'day');
            setBuyTime(adjustedBuyDate);
          } else {
            setBuyTime(buyDate);
          }

          const originalDateStrUDM = formattedData.UltimaDeclaracionMunicipal;
          const udmDate = adjustDate(originalDateStrUDM);
          const originalDayUDM = parseInt(originalDateStrUDM.split('T')[0].split('-')[2], 10); 
          const udmDay = udmDate.date();
          const dayDifferenceUDM = udmDay - originalDayUDM;
          if (dayDifferenceUDM !== 0) {
            const adjustedUDMDate = udmDate.add(-dayDifferenceUDM, 'day');
            setUDMTime(adjustedUDMDate);
          } else {
            setUDMTime(udmDate);
          }

          setData(formattedData);
        } catch (error) {
          console.error("Error en traer la data del usuario", error.message);
        }
      };
      fetchData();
    } else {
      setReset(true);
      setTimeout(() => setReset(false), 0);
    }
  }, [open]);

  const handleEdit = async () => {
    const idLogin = sessionStorage.getItem('loginUser');
    const rawData = {
      Name: getValueRefs.current["Nombre"].value,
      Registration: getValueRefs.current["Matricula"].value,
      BuyDate: buyTime ? dayjs(buyTime).format('YYYY-MM-DD') : null,
      FiscalValue: getValueRefs.current["Valor"].value,
      LastMunicipalDeclaration: UDMTime ? dayjs(UDMTime).format('YYYY-MM-DD') : null,
      Construction: getValueRefs.current["Contruccion"].value,
      Garbage: garbage === "true",
      PublicServices: publicServices === "true",
      PurchasePrice: getValueRefs.current["PrecioCompra"].value,
      SolidarityTax: solidarityTax === "true",
      ID: ID,
      IDUSUARIO: idLogin
    };
    console.log(rawData);

    try {
      const response = await axios.put(env.API_URL + 'UpdateInmueble', rawData);
      if (response.status === 200 && response.data[0].Mensaje === 'OK') {
        Swal.fire('Success', 'El mueble se ha editado exitosamente', 'success').then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire('Error', 'Hubo un problema al editar el mueble (' + response.data[0].Mensaje + ')', 'error');
      }
      handleClose();
    } catch (error) {
      Swal.fire('Error', 'Hubo un problema al establecer la conexión', 'error');
      handleClose();
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{`Editar a ${data.Nombre}`}</DialogTitle>
        <DialogContent>
          <div className='row'>
            <InputEditComponent
              getValue={getValueRefs}
              id="Nombre"
              label="Nombre:"
              type="text"
              placeholder=""
              Avalue={data.Nombre}
              reset={reset}
            />
            <InputEditComponent
              getValue={getValueRefs}
              id="Matricula"
              label="Matricula:"
              type="text"
              placeholder=""
              Avalue={data.Matricula}
              reset={reset}
            />
            <InputEditComponent
              getValue={getValueRefs}
              id="Valor"
              label="Valor Fiscal(₡):"
              type="number"
              placeholder=""
              Avalue={data.ValorFiscal}
              reset={reset}
            />
          </div>

          <div className='row'>
            <InputEditComponent
              getValue={getValueRefs}
              id="PrecioCompra"
              label="Precio de Compra($):"
              type="number"
              placeholder=""
              Avalue={data.PrecioCompra}
              reset={reset}
            />
            <InputEditComponent
              getValue={getValueRefs}
              id="Contruccion"
              label="Construcción(m²):"
              type="number"
              placeholder=""
              Avalue={data.Construccion}
              reset={reset}
            />
            <div className="col-md-4 col-sm-4">
              <label className="form-label">Basura:</label>
              <select 
                className="form-control" 
                id="Basura"
                value={garbage}
                onChange={(e) => setGarbage(e.target.value)}
              >
                <option value="true">Si</option>
                <option value="false">No</option>
              </select>
            </div>
          </div>

          <div className='Row'>
            <div className="col-md-4 col-sm-4">
              <label className="form-label">Servicios Públicos:</label>
              <select 
                className="form-control" 
                id="SP"
                value={publicServices}
                onChange={(e) => setPublicServices(e.target.value)}
              >
                <option value="true">Si</option>
                <option value="false">No</option>
              </select>
            </div>

            <div className="col-md-4 col-sm-4">
              <label className="form-label">Impuestos Solidarios:</label>
              <select 
                className="form-control" 
                id="IS"
                value={solidarityTax}
                onChange={(e) => setSolidarityTax(e.target.value)}
              >
                <option value="true">Si</option>
                <option value="false">No</option>
              </select>
            </div>
            <div className="col-md-4 col-sm-4">
              <LocalizationProvider dateAdapter={AdapterDayjs} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    label="Fecha Compra"
                    value={buyTime}
                    onChange={(newValue) => setBuyTime(newValue)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>
          
          <DialogActions>
            <Button onClick={handleClose} color="primary">Cancelar</Button>
            <Button onClick={handleEdit} color="primary">Guardar</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
