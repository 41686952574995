import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import env from '../../../env-config';

import InputEditComponent from './InputsEditDialog';
import 'bootstrap/dist/css/bootstrap.min.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditUserModal({ open, handleClose }) {
  const [ID, setID] = useState('');
  const [data, setData] = useState({});
  const pmRef = useRef(null);
  const fbRef = useRef(null);
  const typeCedulaRef = useRef(null);
  const [reset, setReset] = useState(false);
  const getValueRefs = useRef({});

  useEffect(() => {
    if (open) {
      const ID = sessionStorage.getItem('CustomerID');
      setID(ID);

      const fetchData = async () => {
        const data2 = { ID: ID };
        try {
          const response = await axios.post(env.API_URL + 'GetCustomer', data2);
          const formattedData = response.data[0];
          setData(formattedData);
        } catch (error) {
          console.error("Error en traer la data del usuario", error.message);
        }
      };
      fetchData();
    } else {
      setReset(true);
      setTimeout(() => setReset(false), 0);
    }
  }, [open]);

  const handleEdit = async () => {
    const idLogin = sessionStorage.getItem('loginUser');
    const rawData = {
      Name: getValueRefs.current["Nombre"].value,
      Address: getValueRefs.current["Direccion"].value,
      Lawyer: getValueRefs.current["Abogado"].value,
      Mail: getValueRefs.current["Correo"].value,
      Identity: getValueRefs.current["Cedula"].value,
      ID_Type: typeCedulaRef.current.value, 
      PM: pmRef.current.value === "true",
      FB: fbRef.current.value === "true",
      ID: ID,
      IDUSUARIO: idLogin
    };

    try {
      const response = await axios.put(env.API_URL + 'UpdateCustomer', rawData);
      console.log('response:', response);
      console.log('status:', response.status);
      if (response.status === 200) {
        Swal.fire('Success', 'Cliente se ha editado exitosamente', 'success').then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire('Error', 'Hubo un problema al editar el Cliente', 'error');
      }
      handleClose();
    } catch (error) {
      Swal.fire('Error', 'Hubo un problema al establecer la conexión', 'error');
      handleClose();
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{`Editar a ${data.Nombre}`}</DialogTitle>
        <DialogContent>
          <div className='row'>
            <InputEditComponent
              getValue={getValueRefs}
              id="Nombre"
              label="Nombre:"
              type="text"
              placeholder=""
              Avalue={data.Nombre}
              reset={reset}
            />
            <InputEditComponent
              getValue={getValueRefs}
              id="Correo"
              label="Correo:"
              type="text"
              placeholder=""
              Avalue={data.Correo}
              reset={reset}
            />
            <InputEditComponent
              getValue={getValueRefs}
              id="Direccion"
              label="Direccion:"
              type="text"
              placeholder=""
              Avalue={data.Direccion}
              reset={reset}
            />
          </div>

          <div className='row'>
            <InputEditComponent
              getValue={getValueRefs}
              id="Abogado"
              label="Abogado:"
              type="text"
              placeholder=""
              Avalue={data.Abogado}
              reset={reset}
            />
            <div className="col-md-4 col-sm-4">
              <label className="form-label">PM</label>
              <select
                className="form-control"
                id="PM"
                value={data.PM ? "true" : "false"}
                onChange={(e) => setData({ ...data, PM: e.target.value === "true" })}
                ref={pmRef}
              >
                <option value="true">Sí</option>
                <option value="false">No</option>
              </select>
            </div>
            <div className="col-md-4 col-sm-4">
              <label className="form-label">FB</label>
              <select
                className="form-control"
                id="FB"
                value={data.FB ? "true" : "false"}
                onChange={(e) => setData({ ...data, FB: e.target.value === "true" })}
                ref={fbRef}
              >
                <option value="true">Sí</option>
                <option value="false">No</option>
              </select>
            </div>
          </div>

          <div className='row'>
            <InputEditComponent
              getValue={getValueRefs}
              id="Cedula"
              label="Identidad:"
              type="text"
              placeholder=""
              Avalue={data.Cedula}
              reset={reset}
            />
            <div className="col-md-4 col-sm-4">
              <label className="form-label">Tipo de Cédula</label>
              <select
                className="form-control"
                id="Tipo_Cedula"
                value={data.Tipo_Cedula || ''} // Cargar el valor inicial
                onChange={(e) => setData({ ...data, Tipo_Cedula: e.target.value })} // Actualizar el estado
                ref={typeCedulaRef} // Asigna la referencia aquí
              >
                <option value="">Seleccionar</option>
                <option value="Cédula">Cédula</option>
                <option value="DIMEX">DIMEX</option>
                <option value="Pasaporte">Pasaporte</option>
                <option value="Licencia de conducir">Licencia de conducir</option>
              </select>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleEdit}>Editar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
