import Table from '../../components/Table';
import React, {  useState, useEffect } from 'react';
import env from '../../env-config';
import axios from 'axios';
import {DeleteFilled,EditFilled} from '@ant-design/icons';
import {Button , Spin} from 'antd';
import EditInmuebleModal from '../../subcomponents/modals/Edit/EditInmueble';
import Swal from 'sweetalert2';


const Title = "Inmuebles";

const columns = ["Nombre", "Matricula", "F.Compra", "Valor Fiscal", "Precio Compra", "Ultima Declaración Municipal", "Construcción(m²)", "Basura", "S.Publicos", "Impuesto Solidario"];



const Inmuebles = () => {
    // eslint-disable-next-line 
    const [selectedRows, setSelectedRows] = useState([]);
    const [data, setData] = useState([]);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // Estado de carga


    //Logica para Editar
  const handleEditModalOpen = async () => {

    if (selectedRows.length === 0) {
      return;
    }

    const selectedUser = data[selectedRows[0].dataIndex];
    const ID = selectedUser[1];
    sessionStorage.setItem('InmuebleID',ID);

    setEditModalOpen(true);
    
  };
  const handleEditModalClose = () => {
    setEditModalOpen(false);
  };

    // Cargar Datos
    useEffect(() => {
    sessionStorage.setItem('currentPage', 'Inmuebles');
     const CustomerID = sessionStorage.getItem('CustomerID');
     if (!CustomerID) {
       // Redirigir al login si no hay compañía
       window.location.href = '/login';
       return;
     }
     
     let URL = env.API_URL;
 
 
     let ID = ''; 
     const TipoBien = sessionStorage.getItem('TipoBien');
 
     if (TipoBien === 'Sociedad') {
       URL = URL + 'GetInmueblesSociety';
       ID = sessionStorage.getItem('SocietyID');
     }
     else{
       URL = URL + 'GetInmueblesCustomer';
       ID = CustomerID;
     }
 
 
     const fetchData = async () => {
 
       const data = {
         ID: ID  
       };
 
       try {
         const response = await axios.post(URL,data);
         const formattedData = response.data.map(Inmueble => [
            Inmueble.Nombre,
            Inmueble.Matricula,
            Inmueble.FechaCompra,
            '₡'+ new Intl.NumberFormat('es-CR').format(Inmueble.ValorFiscal),
            '$'+ new Intl.NumberFormat('en-US').format(Inmueble.PrecioCompra),
            Inmueble.UltimaDeclaracionMunicipal,
            Inmueble.Construccion,
            Inmueble.Basura ? "Sí" : "No",
            Inmueble.ServiciosPublicos ? "Sí" : "No",
            Inmueble.ImpuestoSolidario ? "Sí" : "No"
            
         ]);
         setData(formattedData);
       } catch (error) {
         console.error("Error fetching data", error.message);
       }finally {
        setIsLoading(false); // Cambia el estado de carga
      }
     };
 
     fetchData();
   }, []); 


   const handleDelete = async () => {

    if (selectedRows.length === 0) {
      return;
    }

    const selectedUser = data[selectedRows[0].dataIndex];
    const id = selectedUser[1];
    const idLogin = sessionStorage.getItem('loginUser');
    const matricula = selectedUser[1];


    let URL = env.API_URL;
 
 
     let IDBIEN = ''; 
     const TipoBien = sessionStorage.getItem('TipoBien');
 
     if (TipoBien === 'Sociedad') {
       URL = URL + 'DeleteInmuebleSociety';
       IDBIEN = sessionStorage.getItem('SocietyID');
     }
     else{
       URL = URL + 'DeleteInmuebleCustomer';
       IDBIEN = sessionStorage.getItem('CustomerID');
     }

     const data2 = {
        ID: IDBIEN,
        IDBIEN: id,
        IDUSUARIO: idLogin,
        IDDelete: matricula
     }



 
 
    try {
      const response = await axios.post(URL, 
        data2
      );
      if (response.status === 200) {
        Swal.fire('Success', 'El Inmueble se ha eliminado exitosamente', 'success').then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire('Error', 'Hubo un problema al eliminar el Inmueble', 'error');
      }
    } catch (error) {
      Swal.fire('Error', 'Hubo un problema al eliminar el Inmueble', 'error');
    }
      
  };
   

   const customToolbarSelect = () => {

    return (
      <>
        <div>
        
          <Button type='text' className='toggle' icon={<EditFilled />}  onClick={handleEditModalOpen} title="Editar" />
          <Button type='text' className='toggle' icon={<DeleteFilled />} onClick={handleDelete} title="Borrar" />
          
          
        </div>
      </>
      );
    };
    
    return (
        <div>
           <EditInmuebleModal open={editModalOpen} handleClose={handleEditModalClose} />
           {isLoading ? (
        <Spin tip="Cargando datos..." style={{ display: 'flex', justifyContent: 'center', marginTop: '20%' }} />
      ) : (
            <Table 
            title={Title} 
            data={data} 
            columns={columns}  
            customToolbarSelectFunction={customToolbarSelect} 
            setSelectedRows={setSelectedRows} />  
      )}
        </div>
    )
}

export default Inmuebles;