import React, { useEffect, useState } from 'react';
import Table from '../../components/Table';
import axios from 'axios';
import env from '../../env-config';
import Swal from 'sweetalert2';
import {DeleteFilled,EditFilled,ExportOutlined,ReadOutlined,CarOutlined,HomeOutlined } from '@ant-design/icons';
import {Button, Spin} from 'antd';
import EditSocietyModal from '../../subcomponents/modals/Edit/EditSociety';



const Title = "Sociedades";

const columns = [
    "ID",
    "Nombre", 
    "Cedula", 
    "Domicilio",
    "Agente Residente",
    "Libros",
    "Consecutivo",
    "Ingreso",
    "Retiro",
    "Representantes",
    "Estado",
    "Abogado",
    "Comentario"
  ];
  

const Sociedades = () => {
  // eslint-disable-next-line 
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Estado de carga


  //Logica para Editar
  const handleEditModalOpen = async () => {

    if (selectedRows.length === 0) {
      return;
    }

    const selectedUser = data[selectedRows[0].dataIndex];
    const ID = selectedUser[0];
    sessionStorage.setItem('SocietyID',ID);

    setEditModalOpen(true);
    
  };
  const handleEditModalClose = () => {
    setEditModalOpen(false);
  };

  
  // Cargar Datos
  useEffect(() => {
    sessionStorage.setItem('currentPage', 'Sociedades');
    const user = sessionStorage.getItem('CustomerID');
    if (!user) {
      // Redirigir al login si no hay compañía
      window.location.href = '/login';
      return;
    }
    
    const fetchData = async () => {

      const data = {
        ID: user
      };

      try {
        const response = await axios.post(env.API_URL+'GetSocieties',data);
        const formattedData = response.data.map(user => [
          user.ID,
          user.Nombre,
          user.Cedula,
          user.Domicilio,
          user.AgenteResidente,
          user.Libros,
          user.Consecutivo,
          user.Ingreso,
          user.Retiro,
          user.Representantes,
          user.Estado ? "Activa" : "Inactiva",
          user.Abogado,
          user.Comentario
        ]);
        setData(formattedData);
      } catch (error) {
        console.error("Error fetching data", error.message);
      }finally {
        setIsLoading(false); // Cambia el estado de carga
      }
    };

    fetchData();
  }, []);


  const handleDelete = async () => {

    if (selectedRows.length === 0) {
      return;
    }

    const selectedUser = data[selectedRows[0].dataIndex];
    const id = selectedUser[0];
    const cedula = selectedUser[2];
    const idLogin = sessionStorage.getItem('loginUser');

 
    try {
      const response = await axios.post(env.API_URL+'DeleteSociety', 
        {
          ID: id,
          IDUSUARIO: idLogin,
          IDDelete: cedula
        }
      );
      if (response.status === 200) {
        Swal.fire('Success', 'Sociedad se ha eliminado exitosamente', 'success').then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire('Error', 'Hubo un problema al eliminar el Sociedad', 'error');
      }
    } catch (error) {
      Swal.fire('Error', 'Hubo un problema al eliminar el Sociedad', 'error');
    }
      
  };


  const customToolbarSelect = () => {
    const handleBienes = () => {
      const selectedUser = data[selectedRows[0].dataIndex];
      const id = selectedUser[0];
      sessionStorage.setItem('TipoBien','Sociedad');
      sessionStorage.setItem('SocietyID',id);
      sessionStorage.setItem('lastPage', 'sociedades');
      window.location.href = '/bienes';
    };

    const handleCCT = () => {
      const selectedUser = data[selectedRows[0].dataIndex];
      const id = selectedUser[0];
      sessionStorage.setItem('SocietyID',id);
      sessionStorage.setItem('lastPage', 'sociedades');
      window.location.href = '/cctributario';
    };
  
    const handleMuebles = () => {
      const selectedUser = data[selectedRows[0].dataIndex];
      const id = selectedUser[0];
      
      sessionStorage.setItem('TipoBien','Sociedad');
      sessionStorage.setItem('SocietyID',id);
      sessionStorage.setItem('lastPage', 'sociedades');
      window.location.href = '/muebles';
    };
  
    const handleInmuebles = () => {
      const selectedUser = data[selectedRows[0].dataIndex];
      const id = selectedUser[0];
      
      sessionStorage.setItem('TipoBien','Sociedad');
      sessionStorage.setItem('SocietyID',id);
      sessionStorage.setItem('lastPage', 'sociedades');
      window.location.href = '/inmuebles';
    };
      return (
        <>
          <div>
            <Button type='text' className='toggle' icon={<ExportOutlined />} onClick={handleCCT} title="Ver Detalles" />
            <Button type='text' className='toggle' icon={<CarOutlined />}  onClick={handleMuebles} title="Ver Muebles" />
            <Button type='text' className='toggle' icon={<HomeOutlined />} onClick={handleInmuebles}  title="Ver Inmuebles" />
            <Button type='text' className='toggle' icon={<ReadOutlined />}  onClick={handleBienes} title="Ver bienes" />
            <Button type='text' className='toggle' icon={<EditFilled />}  onClick={handleEditModalOpen} title="Editar" />
            <Button type='text' className='toggle' icon={<DeleteFilled />} onClick={handleDelete} title="Borrar" />
            
            
          </div>
        </>
      );
    };



    return (
        <div>
          <EditSocietyModal open={editModalOpen} handleClose={handleEditModalClose} />
          {isLoading ? (
        <Spin tip="Cargando datos..." style={{ display: 'flex', justifyContent: 'center', marginTop: '20%' }} />
      ) : (
            <Table 
            title={Title} 
            data={data} 
            columns={columns}  
            customToolbarSelectFunction={customToolbarSelect} 
            setSelectedRows={setSelectedRows} />
      )}
        </div>
    )
}

export default Sociedades;