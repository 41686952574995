import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import env from '../../../env-config';
import InputEditComponent from './InputsEditDialog';
import 'bootstrap/dist/css/bootstrap.min.css';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { esES } from '@mui/x-date-pickers/locales';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditMueblesModal({ open, handleClose }) {
  const [ID, setID] = useState('');
  const [data, setData] = useState({});
  const [buyTime, setBuyTime] = useState(dayjs()); 
  const [reset, setReset] = useState(false);
  const getValueRefs = useRef({});



  useEffect(() => {
    if (open) {
      const ID = sessionStorage.getItem('MuebleID');
      setID(ID);

      const fetchData = async () =>{
        const data2 = {
          ID: ID
        }
        try {
          const response = await axios.post(env.API_URL + 'GetMueble', data2);
          const formattedData = response.data[0];

          
          const adjustDate = (dateStr) => {
            if (!dateStr) return null;
            return dayjs(dateStr); 
          };
          
          
          const originalDateStr = formattedData.FechaCompra;
          const buyDate = adjustDate(originalDateStr);
          
          
          const originalDay = parseInt(originalDateStr.split('T')[0].split('-')[2], 10); 
          const buyDay = buyDate.date(); 
          
          const dayDifference = buyDay - originalDay;
          
          // Si los días son diferentes, ajustar la diferencia
          if (dayDifference !== 0) {
            const adjustedBuyDate = buyDate.add(-dayDifference, 'day'); // Ajustar el día en base a la diferencia
            setBuyTime(adjustedBuyDate);
          } else {
            setBuyTime(buyDate);
          }
          

          setData(formattedData);
        } catch (error) {
          console.error("Error en traer la data del usuario", error.message);
        }
      }
      fetchData();
    } else {
      // Reset the values when the modal is closed
      setReset(true);
      setTimeout(() => setReset(false), 0);
    }
  }, [open]);


  

  const handleEdit = async () => {
    const idLogin = sessionStorage.getItem('loginUser');
    const rawData = {
      Name: getValueRefs.current["Nombre"].value,
      Registration: getValueRefs.current["Matricula"].value,
      BuyDate: buyTime ? dayjs(buyTime).format('YYYY-MM-DD') : null,
      FiscalValue: getValueRefs.current["ValorFiscal"].value,
      ID: ID,
      IDUSUARIO: idLogin
    };




    
    try {
      const response = await axios.put(env.API_URL + 'UpdateMueble', rawData);
      if (response.status === 200 && response.data[0].Mensaje === 'OK') {
        Swal.fire('Success', 'El mueble se ha editado exitosamente', 'success').then(() => {
          window.location.reload();
        });
      } else {

        Swal.fire('Error', 'Hubo un problema al editar el mueble ( '  + response.data[0].Mensaje + ' )' , 'error');
      }
      handleClose();
    } catch (error) {
      Swal.fire('Error', 'Hubo un problema al establecer la conexión', 'error');
      handleClose();
    }
      
  };


  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{`Editar a ${data.Nombre}`}</DialogTitle>
        <DialogContent>
          <div className='row'>
            <InputEditComponent
              getValue={getValueRefs}
              id="Nombre"
              label="Nombre:"
              type="text"
              placeholder=""
              Avalue={data.Nombre}
              reset={reset}
            />
            <InputEditComponent
              getValue={getValueRefs}
              id="Matricula"
              label="Matricula:"
              type="text"
              placeholder=""
              Avalue={data.Matricula}
              reset={reset}
            />
            <InputEditComponent
              getValue={getValueRefs}
              id="ValorFiscal"
              label="Valor Fiscal(₡):"
              type="number"
              placeholder=""
              Avalue={data.ValorFiscal}
              reset={reset}
            />
          </div>


          <div className='Row'>
            <div className="col-md-4 col-sm-4">
              <LocalizationProvider dateAdapter={AdapterDayjs}  localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText} >
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    label="FechaCompra"
                    value={buyTime}
                    onChange={(newValue) => setBuyTime(newValue)}
                    format="DD-MM-YYYY"
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>



        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleEdit}>Editar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
