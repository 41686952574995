
import { Routes,Route } from "react-router-dom";
import  LayoutP from "./components/layout";
import Home from "./pages/cliente/home"; 
import Bienes from "./pages/bienes/Bienes";
import Login from "./pages/Principal/Login";
import Inmuebles from "./pages/bienes/Inmuebles";
import Muebles from "./pages/bienes/Muebles";
import Sociedades from "./pages/sociedades/Sociedades";
import Documentos from "./pages/cliente/Documents";
import CCTributario from "./pages/sociedades/CCTributario";
import Usuarios from "./pages/Admin/Usuarios";
import Inmueble from "./pages/bienes/Inmueble";
import Mueble from "./pages/bienes/Mueble";
import Searching from "./pages/cliente/Searching";
import Cliente from "./pages/cliente/Cliente";
import Sociedad from "./pages/sociedades/Sociedad";
import Bitacora from "./pages/Admin/Bitacora";

function App() {
  return (
    <div >
      <Routes>
        <Route element={<LayoutP />} >
          <Route path="/home" element={<Home />} />
          <Route path="/bienes" element={<Bienes />} />
          <Route path="/inmuebles" element={<Inmuebles />} />
          <Route path="/muebles" element={<Muebles />} />
          <Route path="/sociedades" element={<Sociedades />} />
          <Route path="/documentos" element={<Documentos />} />
          <Route path="/cctributario" element={<CCTributario />} />
          <Route path="/usuarios" element={<Usuarios />} />
          <Route path="/inmueble" element={<Inmueble />} />
          <Route path="/mueble" element={<Mueble />} />
          <Route path="/busqueda" element={<Searching />} />
          <Route path="/cliente" element={<Cliente />} />
          <Route path="/sociedad" element={<Sociedad />} />
          <Route path="/bitacora" element={<Bitacora />} />
          <Route path="/Clientes" element={<Home />} />
        </Route>
        <Route path="/" element={<Login/>} />
        <Route path="/login" element={<Login/>} />             
        <Route path="*" element={<h1>Not Found</h1>} />
      </Routes>
    </div>
  );
}

export default App;
