import React, { useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import env from '../../env-config';
import Swal from 'sweetalert2';
import axios from 'axios';
import InputComponent from './InputsDialog';
import 'bootstrap/dist/css/bootstrap.min.css';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ChangePassword({ open, handleClose }) {
  const getValueRefs = useRef({});


  const handleChange = async () => {
    const idLogin = sessionStorage.getItem('loginUser');
    
    const data = {
      Password: getValueRefs.current["Password"].value,
      ID: sessionStorage.getItem('loginNick'),
      IDUSUARIO: idLogin
    };


    
    
    try {
      const response = await axios.put(env.API_URL+'ChangePassword', data);
      if (response.status === 200) {
        Swal.fire('Success', 'Se ha cambiado la contraseña', 'success').then(() => {
          window.location.reload();
        }); 
      } else {
        Swal.fire('Error', 'Hubo un problema al cambiar la contraseña', 'error');
      }
      handleClose();
    } catch (error) {
      console.log('error:', error.message); 
      Swal.fire('Error', 'Hubo un problema al establecer la conexión', 'error');
      handleClose();
    }
    
  };


  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
      >

        <DialogTitle>{"Cambiar Contraseña"}</DialogTitle>
        <DialogContent>
          

          <div className='Row'>
            <InputComponent
              getValue={getValueRefs}

              id="Password"
              label="Nueva Contraseña:"
              type="text"
              placeholder=""
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleChange}>Agregar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
