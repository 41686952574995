import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import '../css/Layout.css';
import { Button, Layout } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined, PlusOutlined, RollbackOutlined } from '@ant-design/icons';
import Logo from '../subcomponents/Layout/Logo';
import MenuList from '../subcomponents/Layout/MenuList';
import MenuListA from '../subcomponents/Layout/MenuListA';
import { Outlet } from 'react-router-dom';
import AlertDialogSlide from '../subcomponents/modals/BasicModal';
import ChangePassword from '../subcomponents/modals/ChangePassword';
import CreateSocieties from '../subcomponents/modals/CreateSocieties';
import CreateInmueble from '../subcomponents/modals/CreateInmueble';
import CreateMueble from '../subcomponents/modals/CreateMueble';
import UserModal from '../subcomponents/modals/UserModal';
import Swal from 'sweetalert2';

const { Header, Sider } = Layout;

function LayoutP() {
  const [Collapsed, setCollapsed] = useState(true);
  const [optionVisibility, setOptionVisibility] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMueblesOpen, setModalMueblesOpen] = useState(false);
  const [modalInmueblesOpen, setModalInmueblesOpen] = useState(false);
  const [modalChangeOpen, setModalChangeOpen] = useState(false);

  const location = useLocation();
  const prevLocationRef = useRef(location.pathname);

  useEffect(() => {
    const currentPage = sessionStorage.getItem('currentPage') || '/';
    const lastPage = sessionStorage.getItem('lastPage') || '/';

    // Asegurarse de que las rutas se guarden con la barra inclinada al inicio
    const currentPath = location.pathname.startsWith('/') ? location.pathname : `/${location.pathname}`;


    if (currentPath === 'Clientes' || currentPath === '/Clientes' ) {
      
      
      if(lastPage === undefined || lastPage === null || lastPage === '' ){
        sessionStorage.setItem('lastPage', currentPage);
        sessionStorage.setItem('currentPage', currentPath);
        prevLocationRef.current = currentPath;
      }
      else
      {
        sessionStorage.setItem('lastPage', lastPage);
        sessionStorage.setItem('currentPage', currentPath);
        prevLocationRef.current = currentPath;
      }
     

    }
    
    else if ( prevLocationRef.current !== currentPath) {
      sessionStorage.setItem('lastPage', prevLocationRef.current );
      sessionStorage.setItem('currentPage', currentPath);
      prevLocationRef.current = currentPath;

      
    }


  }, [location]);

  const handleOptionVisibilityChange = (newState) => {
    setOptionVisibility(newState);
  };

  const handleModalOpen = () => {
    const currentPage = sessionStorage.getItem('currentPage');

    if (currentPage === '/Bienes' || currentPage === 'Bienes') {
      Swal.fire({
        title: 'Seleccione el tipo de bien',
        text: '¿Inmuebles o Muebles?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Inmuebles',
        cancelButtonText: 'Muebles',
      }).then((result) => {
        if (result.isConfirmed) {
          setModalInmueblesOpen(true);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          setModalMueblesOpen(true);
        }
      });
    } else {
      setModalOpen(true);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalChangeOpen = () => {
    setModalChangeOpen(true);
  };

  const handleModalChangeClose = () => {
    setModalChangeOpen(false);
  };

  const handleModalInmueblesClose = () => {
    setModalInmueblesOpen(false);
  };

  const handleModalMueblesClose = () => {
    setModalMueblesOpen(false);
  };

  const setPage = (page) => {
    const formattedPage = page.startsWith('/') ? page : `/${page}`;
    sessionStorage.setItem('currentPage', formattedPage);
  };

  const determineComponent = () => {
    const currentPage = sessionStorage.getItem('currentPage');

    if (currentPage === '/Clientes' || currentPage === 'Clientes') {
      return <AlertDialogSlide open={modalOpen} handleClose={handleModalClose} />;
    } else if (currentPage === '/Sociedades' || currentPage === 'Sociedades') {
      return <CreateSocieties open={modalOpen} handleClose={handleModalClose} />;
    } else if (currentPage === '/Inmuebles' || currentPage === 'Inmuebles') {
      return <CreateInmueble open={modalOpen} handleClose={handleModalClose} />;
    } else if (currentPage === '/Muebles' || currentPage === 'Muebles') {
      return <CreateMueble open={modalOpen} handleClose={handleModalClose} />;
    } else if (currentPage === '/Usuarios' || currentPage === 'Usuarios') {
      return <UserModal open={modalOpen} handleClose={handleModalClose} />;
    }
  };

  const handleGoBack = () => {

    const  CurrentPage = sessionStorage.getItem('currentPage');
    const lastPage = sessionStorage.getItem('lastPage');

    sessionStorage.setItem('lastPage', CurrentPage);
    sessionStorage.setItem('currentPage', lastPage);
    if (lastPage) {
      window.location.href = lastPage;
    } else {
      window.location.href = '/';
    }
  };

  return (
    <Layout>
      <Sider collapsed={Collapsed} theme='light' className="sidebar">
        <Logo />
        {sessionStorage.getItem('loginRol') === 'SU' ?
          <MenuListA setOptionsVisibility={handleOptionVisibilityChange} OpenChangeModal={handleModalChangeOpen} setPage={setPage} /> :
          <MenuList setOptionsVisibility={handleOptionVisibilityChange} OpenChangeModal={handleModalChangeOpen} setPage={setPage} />
        }
      </Sider>
      <Layout className='layout-body'>
        <Header className='layout-header'>
          <div>
            <Button
              type='text'
              className='toggle'
              onClick={() => setCollapsed(!Collapsed)}
              icon={Collapsed ?
                <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            />
            <Button type='text' className='toggle' icon={<RollbackOutlined />} onClick={handleGoBack} />
          </div>
          {optionVisibility && (
            <div>
              <Button type='text' className='toggle' icon={<PlusOutlined />} onClick={handleModalOpen} />
            </div>
          )}
        </Header>
        <Outlet className='layout-page' />
      </Layout>
      <CreateInmueble open={modalInmueblesOpen} handleClose={handleModalInmueblesClose} />
      <CreateMueble open={modalMueblesOpen} handleClose={handleModalMueblesClose} />
      {determineComponent()}
      <ChangePassword open={modalChangeOpen} handleClose={handleModalChangeClose} />
    </Layout>
  );
};

export default LayoutP;
