import Table from '../../components/Table';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import env from '../../env-config';
import {EditFilled } from '@ant-design/icons';
import dayjs from 'dayjs';
import {Button,  Spin} from 'antd';
import EditCCTModal from '../../subcomponents/modals/Edit/EditCCT';


const Title = "Cumplimiento Corporativo Tributario";

const columns = [
    "RTBF", 
    "Fecha Presentación",
    "D140", 
    "D195"
  ];
  


const CCTributario = () => {

  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Estado de carga


  //Logica para Editar
const handleEditModalOpen = async () => {

  if (selectedRows.length === 0) {
    return;
  }

  setEditModalOpen(true);
  
};
const handleEditModalClose = () => {
  setEditModalOpen(false);
};

  // Cargar Datos
  useEffect(() => {
  sessionStorage.setItem('currentPage', 'CCT');
   const societyID = sessionStorage.getItem('SocietyID');

   if (!societyID) {
     // Redirigir al login si no hay compañía
     window.location.href = '/login';
     return;
   }
   



   const fetchData = async () => {

     const data = {
       ID: societyID  
     };

     try {
       const response = await axios.post(env.API_URL + 'GetCCT',data);
       const formattedData = response.data.map(Sociedad => [
          Sociedad.RTBF ? "SI" : "NO",
          dayjs(Sociedad.UltimaFecha).add(1, 'day').format('DD-MM-YYYY'),
          Sociedad.D140 ? "SI" : "NO",
          Sociedad.D195 ? "SI" : "NO"
          
       ]);

       setData(formattedData);
     } catch (error) {
       console.error("Error fetching data", error.message);
     }finally {
      setIsLoading(false); // Cambia el estado de carga
    }
   };

   fetchData();
 }, []); 


 const customToolbarSelect = () => {

    return (
      <>
        <div>
          <Button type='text' className='toggle' icon={<EditFilled />}  onClick={handleEditModalOpen} title="Editar" />       
        </div>
      </>
    );
  };
 
    return (
        <><EditCCTModal open={editModalOpen} handleClose={handleEditModalClose} /><div>
          {isLoading ? (
        <Spin tip="Cargando datos..." style={{ display: 'flex', justifyContent: 'center', marginTop: '20%' }} />
      ) : (
        <Table
          title={Title}
          data={data}
          columns={columns}
          customToolbarSelectFunction={customToolbarSelect}
          setSelectedRows={setSelectedRows} />
      )}
      </div></>
    )
}

export default CCTributario;