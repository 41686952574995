import Table from '../../components/Table';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import env from '../../env-config';
import {EditFilled } from '@ant-design/icons';
import {Button , Spin} from 'antd';
import EditDocumentsModal from '../../subcomponents/modals/Edit/EditDocuments';


const Title = "Documentos";

const columns = [
  "Poderes Inscritos", 
  "Testamentos", 
  "Contratos"
];
  


const Documents = () => {

  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Estado de carga



  //Logica para Editar
const handleEditModalOpen = async () => {

  if (selectedRows.length === 0) {
    return;
  }

  setEditModalOpen(true);
  
};
const handleEditModalClose = () => {
  setEditModalOpen(false);
};

  // Cargar Datos
  useEffect(() => {
  sessionStorage.setItem('currentPage', 'Documents');
   const CustomerID = sessionStorage.getItem('CustomerID');

   if (!CustomerID) {
     // Redirigir al login si no hay compañía
     window.location.href = '/login';
     return;
   }
   



   const fetchData = async () => {

     const data = {
       ID: CustomerID  
     };

     try {
       const response = await axios.post(env.API_URL + 'GetDocuments',data);
       const formattedData = response.data.map(Documento => [
          Documento.Poderes ? "Presenta" : "No Presenta",
          Documento.Testamentos ? "Presenta" : "No Presenta",
          Documento.Contratos ? "Presenta" : "No Presenta",

          
       ]);

       setData(formattedData);
     } catch (error) {
       console.error("Error fetching data", error.message);
     } finally {
      setIsLoading(false); // Cambia el estado de carga
    }
   };

   fetchData();
 }, []); 


 const customToolbarSelect = () => {

    return (
      <>
        <div>
          <Button type='text' className='toggle' icon={<EditFilled />}  onClick={handleEditModalOpen} title="Editar" />       
        </div>
      </>
    );
  };
 
    return (
        <><EditDocumentsModal open={editModalOpen} handleClose={handleEditModalClose} /><div>
          {isLoading ? (
        <Spin tip="Cargando datos..." style={{ display: 'flex', justifyContent: 'center', marginTop: '20%' }} />
      ) : (
        <Table
          title={Title}
          data={data}
          columns={columns}
          customToolbarSelectFunction={customToolbarSelect}
          setSelectedRows={setSelectedRows} />
      )}
      </div></>
    )
}

export default Documents;