import React, { useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import env from '../../env-config';
import Swal from 'sweetalert2';
import axios from 'axios';
import InputComponent from './InputsDialog';
import 'bootstrap/dist/css/bootstrap.min.css';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { esES } from '@mui/x-date-pickers/locales';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateSocieties({ open, handleClose }) {
  const getValueRefs = useRef({});
  const [inTime, setInTime] = useState(null); 
  const [outTime, setOutTime] = useState(null); 
  const [CCFTime, setCCFTime] = useState(null); 

  const handleAdd = async () => {
    
    const CustomerID = sessionStorage.getItem('CustomerID');
    const idLogin = sessionStorage.getItem('loginUser');


    const data = {
      Name: getValueRefs.current["Nombre"].value,
      ID: getValueRefs.current["Cedula"].value,
      Address: getValueRefs.current["Domicilio"].value,
      ResidentAgent: getValueRefs.current["AgenteResidente"].value,
      Books: getValueRefs.current["Libros"].value,
      Consecutive: getValueRefs.current["Consecutivo"].value,
      Income: inTime ? dayjs(inTime).format('YYYY-MM-DD') : null,
      Retirement: outTime ? dayjs(outTime).format('YYYY-MM-DD') : null,
      Representatives: getValueRefs.current["Representantes"].value,
      State: document.getElementById("Estado").value === "true",
      Lawyer: getValueRefs.current["Abogado"].value,
      Comment: getValueRefs.current["Comentarios"].value,
      CustomerID: CustomerID,
      RTBF: document.getElementById("RTFB").value === "true",
      D140: document.getElementById("D140").value === "true",
      D195: document.getElementById("D195").value === "true",
      D101: true,
      LastRTBF: CCFTime ? dayjs(CCFTime).format('YYYY-MM-DD') : null,
      IDUSUARIO: idLogin

    };

    
    try {
      const response = await axios.post(env.API_URL + 'AddSociety', data);
      if (response.status === 200) {
        Swal.fire('Success', 'Se ha creado el cliente', 'success').then(() => {
          window.location.href = '/sociedades';
          window.location.reload();
        }); 
      } else {
        Swal.fire('Error', 'Hubo un problema al crear el cliente', 'error');
      }
      handleClose();
    } catch (error) {
      console.log('error:', error.message); 
      Swal.fire('Error', 'Hubo un problema al establecer la conexión', 'error');
      handleClose();
    }
    
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{"Crear Sociedad"}</DialogTitle>
        <DialogContent>
          <div className='Row'>
            <InputComponent
              getValue={getValueRefs}
              id="Nombre"
              label="Nombre:"
              type="text"
              placeholder=""
            />
            <InputComponent
              getValue={getValueRefs}
              id="Cedula"
              label="Cédula:"
              type="text"
              placeholder=""
            />
            <InputComponent
              getValue={getValueRefs}
              id="Domicilio"
              label="Domicilio:"
              type="text"
              placeholder=""
            />
          </div>

          <div className='Row'>
            <InputComponent
              getValue={getValueRefs}
              id="AgenteResidente"
              label="AgenteResidente:"
              type="text"
              placeholder=""
            />
            <InputComponent
              getValue={getValueRefs}
              id="Libros"
              label="Libros:"
              type="number"
              placeholder=""
            />
            <InputComponent
              getValue={getValueRefs}
              id="Consecutivo"
              label="Consecutivo:"
              type="number"
              placeholder=""
            />
          </div>

          <div className='Row'>
              <div className="col-md-4 col-sm-4">
                  <label className="form-label">Estado:</label>
                  <select className="form-control" id="Estado">
                    <option value="true">Activo</option>
                    <option value="false">Inactivo</option>
                  </select>
              </div>
              <InputComponent
              getValue={getValueRefs}
              id="Abogado"
              label="Abogado:"
              type="text"
              placeholder=""
            />
            <InputComponent
              getValue={getValueRefs}
              id="Comentarios"
              label="Comentarios:"
              type="text"
              placeholder=""
            />

          </div>

          <div className='Row'>
            <InputComponent
              getValue={getValueRefs}
              id="Representantes"
              label="Representantes:"
              type="text"
              placeholder=""
            />

            <div className="col-md-4 col-sm-4">
              <LocalizationProvider dateAdapter={AdapterDayjs} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    label="Ingreso"
                    value={inTime}
                    onChange={(newValue) => setInTime(newValue)}
                    format="DD-MM-YYYY"
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <div className="col-md-4 col-sm-4">
              <LocalizationProvider dateAdapter={AdapterDayjs} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    label="Retiro"
                    value={outTime}
                    onChange={(newValue) => setOutTime(newValue)}
                    format="DD-MM-YYYY"
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>
        </DialogContent>

        <DialogTitle>{"CCT"}</DialogTitle>
        <DialogContent>

        <div className='Row'>
            <div className="col-md-4 col-sm-4">
                  <label className="form-label">RTFB:</label>
                  <select className="form-control" id="RTFB">
                  <option value="true">SI</option>
                  <option value="false">NO</option>
                  </select>
              </div>
              <div className="col-md-4 col-sm-4">
                  <label className="form-label">D140:</label>
                  <select className="form-control" id="D140">
                  <option value="true">SI</option>
                  <option value="false">NO</option>
                  </select>
              </div>
              <div className="col-md-4 col-sm-4">
                  <label className="form-label">D195:</label>
                  <select className="form-control" id="D195">
                  <option value="true">SI</option>
                  <option value="false">NO</option>
                  </select>
              </div>
          </div>


          <div className='Row'>

              <div className="col-md-4 col-sm-4">
              <LocalizationProvider dateAdapter={AdapterDayjs} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    label="Fecha CCF"
                    value={CCFTime}
                    onChange={(newValue) => setCCFTime(newValue)}
                    format="DD-MM-YYYY"
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>

          </div>

        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleAdd}>Agregar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
