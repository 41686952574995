import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import env from '../../../env-config';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { esES } from '@mui/x-date-pickers/locales';
import 'bootstrap/dist/css/bootstrap.min.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditCCTModal({ open, handleClose }) {
  const [ID, setID] = useState('');
  const [data, setData] = useState({});
  // eslint-disable-next-line 
  const [reset, setReset] = useState(false);
  const [CCFTime, setCCFTime] = useState(null); 

  useEffect(() => {
    if (open) {
      const ID = sessionStorage.getItem('SocietyID');
      setID(ID);

      const fetchData = async () => {
        const data2 = { ID };
        try {
          const response = await axios.post(env.API_URL + 'GetCCT', data2);
          const formattedData = response.data[0];

          const adjustDate = (dateStr) => {
            if (!dateStr) return null;
            return dayjs(dateStr); 
          };

          const originalIngresoStr = formattedData.UltimaFecha;
          const ingresoDate = adjustDate(originalIngresoStr);
          const ingresoDay = parseInt(originalIngresoStr.split('T')[0].split('-')[2], 10);
          const ingresoBuyDay = ingresoDate.date(); 
          const ingresoDayDifference = ingresoBuyDay - ingresoDay;

          if (ingresoDayDifference !== 0) {
            const adjustedIngresoDate = ingresoDate.add(-ingresoDayDifference, 'day');
            setCCFTime(adjustedIngresoDate);
          } else {
            setCCFTime(ingresoDate);
          }

          console.log(formattedData);
          setData(formattedData);
        } catch (error) {
          console.error("Error en traer la data de la sociedad", error.message);
        }
      }
      fetchData();
    } else {
      setReset(true);
      setID('');
      setData({});
      setCCFTime(null);
      setTimeout(() => setReset(false), 0);
    }
  }, [open]);

  const handleSelectChange = (e) => {
    const { id, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleEdit = async () => {
    const idLogin = sessionStorage.getItem('loginUser');
    const rawData = {
      ID,
      RTBF: document.getElementById("RTFB").value === "true",
      D140: document.getElementById("D140").value === "true",
      D195: document.getElementById("D195").value === "true",
      D101: true, //ya no se usa...
      LastRTBF: CCFTime ? dayjs(CCFTime).format('YYYY-MM-DD') : null,
      IDUSUARIO: idLogin
    };

    try {
      const response = await axios.put(env.API_URL + 'UpdateCCT', rawData);

      if (response.status === 200) {
        Swal.fire('Success', 'Cumplimiento Corporativo Tributario se ha editado exitosamente', 'success').then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire('Error', 'Hubo un problema al editar el Cumplimiento Corporativo Tributario', 'error');
      }
      handleClose();
    } catch (error) {
      Swal.fire('Error', 'Hubo un problema al establecer la conexión', 'error');
      handleClose();
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{`Editar CCT`}</DialogTitle>
        <DialogContent>
          <div className='Row'>
            <div className="col-md-4 col-sm-4">
              <label className="form-label">RTFB:</label>
              <select className="form-control" id="RTFB"
              value={data.RTFB || ''}
              onChange={handleSelectChange}>
                <option value="true">Si</option>
                <option value="false">No</option>
              </select>
            </div>
            <div className="col-md-4 col-sm-4">
              <label className="form-label">D140:</label>
              <select className="form-control" id="D140"
              value={data.D140 || ''}
              onChange={handleSelectChange}>
                <option value="true">Si</option>
                <option value="false">No</option>
              </select>
            </div>
            <div className="col-md-4 col-sm-4">
              <label className="form-label">D195:</label>
              <select className="form-control" id="D195"
              value={data.D195 || ''}
              onChange={handleSelectChange}>
                <option value="true">Si</option>
                <option value="false">No</option>
              </select>
            </div>
          </div>

          <div className='Row'>
            <div className="col-md-4 col-sm-4">
              <LocalizationProvider dateAdapter={AdapterDayjs} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    label="Fecha CCF"
                    value={CCFTime}
                    onChange={(newValue) => setCCFTime(newValue)}
                    format="DD-MM-YYYY"
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleEdit}>Editar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
