import MUIDataTable from "mui-datatables";
import React from "react";
import * as XLSX from 'xlsx'; // Importa la biblioteca XLSX

/*
--------------------------------------------------------------------------------------------------------------
Esta función obtiene la fecha actual en formato yyyy-mm-dd
--------------------------------------------------------------------------------------------------------------
E: No recibe parámetros
S: Retorna la fecha actual en formato yyyy-mm-dd
*/
const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Agrega ceros iniciales si es necesario
    const day = String(today.getDate()).padStart(2, '0'); // Agrega ceros iniciales si es necesario
    return `${year}-${month}-${day}`;
};


// Función para crear una tabla con los datos recibidos
const Table = ({ title, data, columns, customToolbarSelectFunction, setSelectedRows }) => {


    /*
    --------------------------------------------------------------------------------------------------------------
    Configuración de la tabla con las siguientes opciones:
    - filterType: 'checkbox' permite seleccionar varias filas
    - onRowSelectionChange: Se ejecuta cuando se selecciona una fila
    - print: false, no permite imprimir la tabla
    - responsive: "standard", permite que la tabla sea responsive
    - textLabels: Configuración de los textos de la tabla
    - downloadOptions: Configuración de la descarga de la tabla
    --------------------------------------------------------------------------------------------------------------
    */
    const options = {
        selectableRows: "single",

        print: false,
        responsive: "standard",
        textLabels: {
            body: {
                noMatch: "No se han encontrado resultados con los datos digitados",
                toolTip: "Ordenar",
                columnHeaderTooltip: column => `Ordenar  ${column.label}`
            },
            pagination: {
                next: "Siguiente Página",
                previous: "Anterior Página",
                rowsPerPage: "Filas por página:",
                displayRows: "de"
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar XLSX", // Cambia el texto para reflejar que ahora descarga XLSX
                print: "Imprimir",
                viewColumns: "Ver Columnas",
                filterTable: "Filtrar Tabla",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Reiniciar",
            },
            viewColumns: {
                title: "Mostrar Columnas",
                titleAria: "Mostrar/Ocultar Columnas",
            },
            selectedRows: {
                text: "Fila(s) seleccionada",
                delete: "Eliminar",
                deleteAria: "Eliminar Columna Seleccionada",
            },
        },
        downloadOptions: {
            filename: `${title}_${getCurrentDate()}.xlsx`, // Cambia la extensión a .xlsx
            separator: ',', // Esto ya no es necesario para XLSX, pero lo mantengo por compatibilidad
            filterOptions: {
                useDisplayedColumnsOnly: true, // Solo descargar las columnas visibles
                useDisplayedRowsOnly: false
            },
        },
        onDownload: (buildHead, buildBody, columns, data) => {
            // Preparar los datos para el archivo XLSX
            const visibleColumns = columns.map(column => column.label); // Obtener etiquetas de columnas
            const visibleData = data.map(row => row.data); // Obtener los datos visibles

            // Crear el libro y la hoja de trabajo de Excel
            const worksheet = XLSX.utils.aoa_to_sheet([visibleColumns, ...visibleData]);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

            // Generar el archivo Excel y descargarlo
            XLSX.writeFile(workbook, `${title}_${getCurrentDate()}.xlsx`);

            // Retornar false para evitar el comportamiento de descarga por defecto
            return false;
        },
        customToolbarSelect: customToolbarSelectFunction,
        onRowSelectionChange: (currentRowsSelected, allRowsSelected) => {
            setSelectedRows(allRowsSelected);
        }
    };

    return (
        <div>
            <MUIDataTable
                title={title}
                data={data}
                columns={columns}
                options={options}
            />
        </div>
    );
}


export default Table;

