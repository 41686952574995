
import React, { useEffect, useState } from 'react';
import Table from '../../components/Table';
import axios from 'axios';
import env from '../../env-config';
import { Spin } from 'antd';




const Title = "Bitacora";

const columns = ["Nombre", "Detalle", "Fecha"];




const Bitacora = () => {

  const [data, setData] = useState([]);
  // eslint-disable-next-line
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Estado de carga

  // Cargar Datos
  useEffect(() => {
    const user = sessionStorage.getItem('loginUser');
    const rol = sessionStorage.getItem('loginRol');
    if (!user || rol !== 'SU') {
      // Redirigir al login si no hay compañía
      window.location.href = '/login';
      return;
    }
    
    const fetchData = async () => {


      try {
        const response = await axios.get(env.API_URL+'GetBitacora');
        const formattedData = response.data.map(Register => [

          Register.Nombre,
          Register.Descripcion,
          Register.Fecha
        ]);
        setData(formattedData);
      } catch (error) {
        console.error("Error fetching data", error.message);
      } finally {
        setIsLoading(false); // Cambia el estado de carga
      }
    };

    fetchData();
  }, []);


  const customToolbarSelect = () => {

  
      return (
        <>
          <div>

                        
          </div>
        </>
      );
    };
  
    return (
            
        <>
{isLoading ? (
        <Spin tip="Cargando datos..." style={{ display: 'flex', justifyContent: 'center', marginTop: '20%' }} />
      ) : (
          <Table 
          title={Title} 
          data={data} 
          columns={columns} 
          customToolbarSelectFunction={customToolbarSelect} 
          setSelectedRows={setSelectedRows}
          />)}
        </>

        
    );
    }
export default Bitacora;

