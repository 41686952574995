import React, { useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import env from '../../env-config';
import Swal from 'sweetalert2';
import axios from 'axios';
import InputComponent from './InputsDialog';
import 'bootstrap/dist/css/bootstrap.min.css';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { esES } from '@mui/x-date-pickers/locales';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateInmueble({ open, handleClose }) {
  const getValueRefs = useRef({});
  const [buyTime, setBuyTime] = useState(dayjs());
  const [UDMTime, setUDMTime] = useState(dayjs());

  const [garbage, setGarbage] = useState("true");
  const [publicServices, setPublicServices] = useState("true");
  const [solidarityTax, setSolidarityTax] = useState("true");

  const handleAdd = async () => {
    let URL = env.API_URL;
    let ID = '';
    const TipoBien = sessionStorage.getItem('TipoBien');

    if (TipoBien === 'Sociedad') {
      URL += 'AddInmueblesSociedades';
      ID = sessionStorage.getItem('SocietyID');
    } else {
      URL += 'AddInmueblesCliente';
      ID = sessionStorage.getItem('CustomerID');
    }

    const idLogin = sessionStorage.getItem('loginUser');

    const data = {
      Name: getValueRefs.current["Nombre"].value,
      Registration: getValueRefs.current["Matricula"].value,
      BuyDate: buyTime ? dayjs(buyTime).format('YYYY-MM-DD') : null,
      FiscalValue: getValueRefs.current["Valor"].value,
      LastMunicipalDeclaration: UDMTime ? dayjs(UDMTime).format('YYYY-MM-DD') : null,
      Construction: getValueRefs.current["Contruccion"].value,
      Garbage: garbage === "true",
      PublicServices: publicServices === "true",
      PurchasePrice: getValueRefs.current["PrecioCompra"].value,
      SolidarityTax: solidarityTax === "true",
      ID: ID,
      IDUSUARIO: idLogin,
    };

    try {
      const response = await axios.post(URL, data);
      if (response.status === 200 && response.data[0].Mensaje === 'OK') {
        Swal.fire('Success', 'El Inmueble se ha editado exitosamente', 'success').then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire('Error', 'Hubo un problema al crear el Inmueble (Ya existe esa matricula)', 'error');
      }
      handleClose();
    } catch (error) {
      console.log('error:', error.message);
      Swal.fire('Error', 'Hubo un problema al establecer la conexión', 'error');
      handleClose();
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{"Crear Inmueble"}</DialogTitle>
        <DialogContent>
          <div className='Row'>
            <InputComponent getValue={getValueRefs} id="Nombre" label="Nombre:" type="text" placeholder="" />
            <InputComponent getValue={getValueRefs} id="Matricula" label="Matricula:" type="text" placeholder="" />
            <InputComponent getValue={getValueRefs} id="Valor" label="Valor Fiscal(₡):" type="number" placeholder="" />
          </div>

          <div className='Row'>
            <InputComponent getValue={getValueRefs} id="Contruccion" label="Construcción(m²):" type="number" placeholder="" />
            <InputComponent getValue={getValueRefs} id="PrecioCompra" label="Precio de Compra($):" type="number" placeholder="" />

            <div className="col-md-4 col-sm-4">
              <label className="form-label">Basura:</label>
              <select className="form-control" value={garbage} onChange={(e) => setGarbage(e.target.value)}>
                <option value="true">Si</option>
                <option value="false">No</option>
              </select>
            </div>
          </div>

          <div className='Row'>
            <div className="col-md-4 col-sm-4">
              <label className="form-label">Servicios Públicos:</label>
              <select className="form-control" value={publicServices} onChange={(e) => setPublicServices(e.target.value)}>
                <option value="true">Si</option>
                <option value="false">No</option>
              </select>
            </div>
            <div className="col-md-4 col-sm-4">
              <label className="form-label">Impuestos Solidarios:</label>
              <select className="form-control" value={solidarityTax} onChange={(e) => setSolidarityTax(e.target.value)}>
                <option value="true">Si</option>
                <option value="false">No</option>
              </select>
            </div>
            <div className="col-md-4 col-sm-4">
              <LocalizationProvider dateAdapter={AdapterDayjs} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    label="FechaCompra"
                    value={buyTime}
                    onChange={(newValue) => setBuyTime(newValue)}
                    format="DD-MM-YYYY"
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>

          <div className='Row'>
            <div className="col-md-4 col-sm-4">
              <LocalizationProvider dateAdapter={AdapterDayjs} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    label="Ultima Declaración Municipal"
                    value={UDMTime}
                    onChange={(newValue) => setUDMTime(newValue)}
                    format="DD-MM-YYYY"
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleAdd}>Agregar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
